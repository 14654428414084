import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import 'theme/iconly.css'
// import 'theme/slick.css'
// import 'theme/slick-theme.css'


Vue.use(BootstrapVue)
