<template>
<div id="app">
    <div id="loader-wrapper" class="" v-if="$root.isLoading">
        <div class="loader">
            <div class="inner one"></div>
            <div class="inner two"></div>
            <div class="inner three"></div>
        </div>
    </div>

    <div>
        <div class="index-page">
            <div class="main-wrap">
                <!--  <div class="search-box">
            <font-awesome-icon icon="search" class="search"></font-awesome-icon>
            <input class="form-control" type="search" placeholder="Nhập từ khóa">
            </div> -->

                <div class="wrapper">
                    <transition :name="$store.state.pageTransition.name" :mode="$store.state.pageTransition.mode" v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
                        <router-view class="transition" />
                    </transition>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<!-- https://amirrezajef.ir/iconly/demo.html -->

<style lang="scss">
@import './assets/css/style.css';
@import './assets/iconly/style.css';
@import './assets/iconly/bulk-style.css';

:root {
    --link-color: #459042;
}

img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: 100%;
}

body {
  position: relative;
  margin: auto;
  max-width: 980px;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body #app .main-wrap {
    padding: 0px;
    height: 100%;
}

.index-page {
    height: 100%;
    // padding-bottom: 60px;
    width: 100%;
    overflow-y: auto;
    position: relative;
    background: #FFF;
}

.wrapper .transition{
  padding: 5px 10px;
}

.footer-wrap .footer .footer-item .footer-link.footer-qr {
    position: relative;
    border: 1px solid #FFF;
    padding: 15px;
    border-radius: 50px;
    background: #FFF;
    color: #0baf9a;
    width: 55px;
    margin: auto;
}

.footer-wrap .footer .footer-item .footer-link.footer-qr .iconlyBulk-Scan * {
    font-size: 25px;
    color: #0baf9a;
}

.form-group label {
    margin: 0;
    padding: 0;
}

.form-group input,
.btn-solid {
    border-radius: 0px;
    box-shadow: none;
}

.loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0;
    top: 0;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid var(--link-color);
}

.inner.two {
    right: 0;
    top: 0;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid var(--link-color);
}

.inner.three {
    right: 0;
    bottom: 0;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid var(--link-color);
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

#loader-wrapper {
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #e5f3f0a6;
    opacity: 1;
    -webkit-transition: all 500ms linear 0;
    -moz-transition: all 500ms linear 0;
    -ms-transition: all 500ms linear 0;
    -o-transition: all 500ms linear 0;
    transition: all 500ms linear 0;
}

$duration: 0.5s;

.transition {
    overflow: hidden;
    background: #FFF;
    height: 100%;
}

.router-view,
.router-view-back {

    &-enter-active,
    &-leave-active {
        position: fixed;
        width: 100%;
        background: white;
        min-height: 100vh;
        top: 0;
        z-index: 1;
    }
}

// router view
.router-view-enter-active {
    transition: transform $duration ease-in-out;
    z-index: 2;
    transform: translateX(100%);
}

.router-view-enter-to {
    z-index: 2;
    transform: translateX(0%);
}

.router-view-leave-active {
    z-index: -1;
}

.router-view-leave-to {
    z-index: -1;
}

// router view back

.router-view-back-leave-active {
    transition: transform $duration ease-in-out;
    z-index: 3;
    transform: translateX(0%);
}

.router-view-back-leave-to {
    z-index: 3;
    transform: translateX(100%);
}
</style>

<script>
import Store from "./store/index";

export default {
    name: "App",
    components: {},
    methods: {
        afterEnter: () => {
            window.scrollTo(0, 0);
        },
        afterLeave: () => {
            Store.commit("setPageTransition", "default");
        }
    }
};
</script>
