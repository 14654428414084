import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import './plugins/fontawesome'
import './plugins/bootstrap-vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import App from './App.vue'

import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  data () {
    return {
      customer: localStorage.getItem('customer_login') ? JSON.parse(localStorage.getItem('customer_login')) : {},
      token: 'hogo YdCUzRGEQFxIudJfHSjLdNeHrgQqrO7rnsPIxSx0',
      url_api: 'https://id.hogo.vn',
      isLoading: false,
      app_token: 'hogo YdCUzRGEQFxIudJfHSjLdNeHrgQqrO7rnsPIxSx0',
      url_app_api: 'https://app.hogo.vn',
      isShowPassword: false
    }
  },
  render: h => h(App)
}).$mount('#app')
