import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },{
    path: '/info-app',
    name: 'infoApp',
    component: () => import('@/views/InfoAppView.vue')
  },{
    path: '/support',
    name: 'support',
    component: () => import('@/views/SupportView.vue')
  },{
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileView.vue')
  },{
    path: '/update-password',
    name: 'update-password',
    component: () => import('@/views/UpdatePasswordView.vue')
  },{
    path: '/account',
    name: 'account',
    component: () => import('@/views/AccountView.vue')
  },{
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },{
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterView.vue')
  },{
    path: '/resset-password',
    name: 'resset-password',
    component: () => import('@/views/RessetPasswordView.vue')
  },{
    path: '/scan-qr',
    name: 'scan-qr',
    component: () => import('@/views/ScanQRView.vue')
  },{
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/PromotionView.vue')
  },{
    path: '/history',
    name: 'history',
    component: () => import('@/views/HistoryView.vue')
  },{
    path: '/schedule',
    name: 'schedule',
    component: () => import('@/views/ScheduleView.vue')
  },{
    path: '/dang-ky-hoc-nhanh',
    name: 'dk-hoc-nhanh',
    component: () => import('@/views/hlx/DKNView.vue')
  },{
    path: '/dang-ky-hoc-xe-theo-partner',
    name: 'dk-hoc-xe-theo-partner',
    component: () => import('@/views/hlx/DKPartnerView.vue')
  },{
    path: '/dang-ky-hoc-lai-xe-hang-a',
    name: 'dk-hoc-lai-xe-hang-a',
    component: () => import('@/views/hlx/DKHangAView.vue')
  },{
    path: '/dang-ky-hoc-lai-xe-hang-b',
    name: 'dk-hoc-lai-xe-hang-b',
    component: () => import('@/views/hlx/DKHangBView.vue')
  },{
    path: '/dang-ky-hoc-lai-xe-hang-c',
    name: 'dk-hoc-lai-xe-hang-c',
    component: () => import('@/views/hlx/DKHangCView.vue')
  },{
    path: '/dang-ky-hoc-nang-hang',
    name: 'dk-hoc-nang-hang',
    component: () => import('@/views/hlx/DKNangHangView.vue')
  },{
    path: '/dang-ky-hoc-nghanh-khac',
    name: 'dk-hoc-nghanh-khac',
    component: () => import('@/views/hlx/DKHocKhacView.vue')
  },

  {
    path: '/de-thi',
    name: 'de-thi',
    component: () => import('@/views/dethi/IndexView.vue')
  },{
    path: '/de-thi/hang-a',
    name: 'de-thi-hang-a',
    component: () => import('@/views/dethi/oto/HangAView.vue')
  },{
    path: '/de-thi/hang-a/de-so/:dethi',
    name: 'de-thi-hang-a-de-so',
    component: () => import('@/views/dethi/oto/HangADTView.vue')
  },{
    path: '/de-thi/hang-a-nang-cao',
    name: 'de-thi-hang-a-nang-cao',
    component: () => import('@/views/dethi/oto/HangANangCaoView.vue')
  },{
    path: '/de-thi/hang-a-nang-cao/de-so/:dethi',
    name: '/de-thi/hang-a-nang-cao/de-so',
    component: () => import('@/views/dethi/oto/HangANangCaoDTView.vue')
  }
  ,{
    path: '/de-thi/hang-b',
    name: 'de-thi-hang-b',
    component: () => import('@/views/dethi/oto/HangBView.vue')
  },{
    path: '/de-thi/hang-b/de-so/:dethi',
    name: '/de-thi/hang-b/de-so',
    component: () => import('@/views/dethi/oto/HangBDTView.vue')
  },{
    path: '/de-thi/hang-c',
    name: 'de-thi-hang-c',
    component: () => import('@/views/hlx/DKNView.vue')
  },{
    path: '/de-thi/hang-c/de-so/:dethi',
    name: 'de-thi-hang-c-de-so',
    component: () => import('@/views/hlx/DKNView.vue')
  },{
    path: '/de-thi/nang-hang',
    name: 'de-thi-nang-hang',
    component: () => import('@/views/hlx/DKNView.vue')
  }

]

const router = new VueRouter({
  // mode: "history",
  base: "/",
  routes: routes,
  // scrollBehavior(to, from, SavedPosition) {
  //   if (to.hash) {
  //     const el = window.location.href.split("#")[1];
  //     if (el.length) {
  //       document.getElementById(el).scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else if (SavedPosition) {
  //     return SavedPosition;
  //   } else {
  //     document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  //   }
  // },
})

export default router
