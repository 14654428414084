<template>
<div class="home">
    <header class="header" style="border-bottom: 1px solid #0baf9a;">
        <div class="logo-wrap">
          <router-link to="/">
            <img class="logo logo-w" src="../assets/hogo.png" alt="logo" />
          </router-link>

          <router-link to="/">
            <img class="logo" src="../assets/hogo.png" alt="logo" />
          </router-link>
        </div>
        <template v-if="this.$root.customer.user">
            <router-link to="/account" class="avatar-wrap">
                <span class="font-sm">
                    Chào: <strong>{{ this.$root.customer.user.code }}</strong>
                </span>
                <img class="avatar" src="@/assets/login.png" alt="avatar" />
            </router-link>
        </template>
        <template v-else>
             <router-link to="/login" class="avatar-wrap">
                <span class="font-sm">
                    Đăng nhập
                </span>
                <img class="avatar" src="@/assets/login.png" alt="avatar" />
            </router-link>
        </template>
    </header>

    <section class="banner-section ratio2_1 pt-3 pb-0">
        <VueSlickCarousel class="h-banner-slider" v-bind="settings">
            <div>
                <div class="banner-box">
                    <img src="../assets/banner/1.jpg" alt="banner" class="bg-img" />
                    <div class="content-box">
                        <h2 class="font-white font-md heading">Đăng ký học B2</h2>
                        <p class="font-white font-sm">Giảm ngay 3.000.000đ</p>
                        <router-link to="/dang-ky-hoc-lai-xe-hang-b" class="btn-solid font-sm">Đăng ký ngay</router-link>
                    </div>
                </div>
            </div>
            <div>
                <div class="banner-box">
                    <img src="../assets/banner/2.jpg" alt="banner" class="bg-img" />
                    <div class="content-box">
                        <h2 class="font-white font-md heading">HOGO Việt Nam</h2>
                        <p class="font-white font-sm">An Tâm - Tin cậy</p>
                        <router-link to="/about" class="btn-solid font-sm">Xem ngay</router-link>
                    </div>
                </div>
            </div>
            <div>
                <div class="banner-box">
                    <img src="../assets/banner/3.jpg" alt="banner" class="bg-img" />
                    <div class="content-box">
                        <h2 class="font-white font-md heading">Bộ đề thi lái xe</h2>
                        <p class="font-white font-sm">Cập nhật mới nhất của BGTVT</p>
                        <router-link to="/de-thi" class="btn-solid font-sm">Xem ngay</router-link>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
    </section>

    <section class="category pt-3 pb-0">
        <h3>
            <span>ĐÀO TẠO LÁI XE</span>
            <span class="line"></span>
        </h3>
        <div class="row">
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/dang-ky-hoc-nhanh">
                    <div class="category-wrap">
                        <router-link to="/dang-ky-hoc-nhanh">
                            <img class="category img-fluid" src="../assets/dang-ky.png" alt="category" />
                            <span class="title-color">Đăng ký học nhanh</span>
                        </router-link>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3">
                <router-link to="/dang-ky-hoc-xe-theo-partner">
                    <div class="category-wrap">
                        <router-link to="/dang-ky-hoc-xe-theo-partner">
                            <img class="category img-fluid" src="../assets/dang-ky-dai-ly.png" alt="category" />
                        </router-link>
                        <span class="title-color">Đăng ký học theo đại lý</span>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3">
                <router-link to="/dang-ky-hoc-lai-xe-hang-a">
                    <div class="category-wrap">
                        <router-link to="/dang-ky-hoc-lai-xe-hang-a">
                            <img class="category img-fluid" src="../assets/hang-a.png" alt="category" />
                        </router-link>
                        <span class="title-color">Đăng ký học hạng A</span>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/dang-ky-hoc-lai-xe-hang-b">
                    <div class="category-wrap">
                        <router-link to="/dang-ky-hoc-lai-xe-hang-b">
                            <img class="category img-fluid" src="../assets/car.png" alt="category" />
                        </router-link>
                        <span class="title-color">Đăng ký học hạng B</span>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/dang-ky-hoc-lai-xe-hang-c">
                    <div class="category-wrap">
                        <router-link to="/dang-ky-hoc-lai-xe-hang-c">
                            <img class="category img-fluid" src="../assets/truck.png" alt="category" />
                        </router-link>
                        <span class="title-color">Đăng ký học hạng C</span>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/dang-ky-hoc-nang-hang">
                    <div class="category-wrap">
                        <router-link to="/dang-ky-hoc-nang-hang">
                            <img class="category img-fluid" src="../assets/nang-hang.png" alt="category" />
                        </router-link>
                        <span class="title-color">Đăng ký học nâng hạng</span>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
    <!-- <section class="category pt-3 pb-0">
        <h3>
          <span>Bộ đề thi lái xe</span>
          <span class="line"></span>
        </h3>
        <div class="row">
          <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
            <router-link to="/de-thi/hang-a">
              <div class="category-wrap">
                <router-link to="/de-thi/hang-a">
                  <img class="category img-fluid" src="../assets/a.png" alt="category" />
                  <span class="title-color">Bộ</span>
                </router-link>
              </div>
            </router-link>
          </div>
        </div>
      </section> -->
    <section class="category pt-3 pb-0">
        <h3>
            <span>ĐỀ THI</span>
            <span class="line"></span>
        </h3>
        <div class="row">
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/de-thi/hang-a">
                    <div class="category-wrap">
                        <router-link to="/de-thi/hang-a">
                            <img class="category img-fluid" src="../assets/a.png" alt="category" />
                            <span class="title-color">Hạng A1</span>
                        </router-link>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/de-thi/hang-a">
                    <div class="category-wrap">
                        <router-link to="/de-thi/hang-a-nang-cao">
                            <img class="category img-fluid" src="../assets/a.png" alt="category" />
                            <span class="title-color">Hạng A2, A3, A4</span>
                        </router-link>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/de-thi/hang-b">
                    <div class="category-wrap">
                        <router-link to="/de-thi/hang-b">
                            <img class="category img-fluid" src="../assets/b.png" alt="category" />
                            <span class="title-color">Hạng B1</span>
                        </router-link>
                    </div>
                </router-link>
            </div>
            <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
                <router-link to="/de-thi/hang-b">
                    <div class="category-wrap">
                        <router-link to="/de-thi/hang-b">
                            <img class="category img-fluid" src="../assets/b.png" alt="category" />
                            <span class="title-color">Hạng B2</span>
                        </router-link>
                    </div>
                </router-link>
            </div>

            <!-- <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
            <router-link to="/de-thi/hang-c">
              <div class="category-wrap">
                <router-link to="/de-thi/hang-c">
                  <img class="category img-fluid" src="../assets/c.png" alt="category" />
                  <span class="title-color">Giấy phép lái xe <br /> hạng C</span>
                </router-link>
              </div>
            </router-link>
          </div>
          <div class="col-4 col-md-3 col-lg-2 col-ms-3 mb-3">
            <router-link to="/de-thi/nang-hang">
              <div class="category-wrap">
                <router-link to="/de-thi/nang-hang">
                  <img class="category img-fluid" src="../assets/up.png" alt="category" />
                  <span class="title-color">Giấy phép lái xe <br /> nâng hạng</span>
                </router-link>
              </div>
            </router-link>
          </div>
           -->
        </div>
    </section>
    <footer class="footer-wrap" style="width: 100%;padding-top: 0;">
            <ul class="footer">
                <li class="footer-item">
                    <router-link class="footer-link" to="/">
                        <i class="iconly-Home icli"></i>
                        <span>HOGO</span>
                    </router-link>
                </li>
                <li class="footer-item">
                    <router-link class="footer-link" to="/promotion">
                        <i class="iconly-Discount icli"></i>
                        <span>Ưu đãi</span>
                    </router-link>
                </li>
                <!-- <li class="footer-item">
                    <router-link class="footer-link footer-qr" to="/scan-qr">
                        <span class="iconlyBulk-Scan">
                            <span class="path1"></span>
                            <span class="path2"></span>
                        </span>
                    </router-link>
                </li> -->
                <li class="footer-item">
                    <router-link class="footer-link" to="/schedule">
                        <i class="iconly-Calendar icli"></i>
                        <span>Lịch học</span>
                    </router-link>
                </li>
                <li class="footer-item">
                    <router-link class="footer-link" to="/about">
                        <i class="iconly-Calling icli"></i>
                        <span>Liên hệ</span>
                    </router-link>
                </li>
            </ul>
        </footer>
</div>
</template>


<style>
/* .index-page {
    height: calc(100vh - 60px);
    padding-bottom: 60px;
} */
.banner-section .banner-box .content-box {
    width: 100%;
    background: #459042e3;
}

.slick-slide img {
    display: block;
    width: 100%;
    height: 190px;
    object-fit: cover;
    object-position: center;
}

.category .category-wrap a .category {
    border: 1px solid #459042;
    padding: 5px;
    border-radius: 15px;
    box-shadow: 0px 0px 3px 0px #459042;
}
</style>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            settings: {
                dots: false,
                slidesToShow: 1,
                infinite: true,
                centerMode: true,
                centerPadding: "50px",
                arrows: false,
                slidesToScroll: 1,
                responsive: [{
                        breakpoint: 475,
                        settings: {
                            centerPadding: "20px",
                        },
                    },

                    {
                        breakpoint: 375,
                        settings: {
                            centerPadding: "15px",
                        },
                    },
                ],
            }
        }
    },
}
</script>
